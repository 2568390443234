.section {
  composes: section from './QuoteSection.module.css';
  border-radius: 2rem;
  gap: 92px;
}

.mainImage {
  composes: mainImage from './QuoteSection.module.css';
  border-radius: 1.5rem 7.125rem 1.5rem 1.5rem;
  background-image: url('/lp/assets/cz/quotesection/main_image_cz.png');
  margin: 1.5rem;
}

.quoteFooter {
  composes: quoteFooter from './QuoteSection.module.css';
  border-style: none;
}

.quote {
  composes: quote from './QuoteSection.module.css';
  font-size: 3rem;

  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3rem;
  text-align: left;
}

.quoteDecoration {
  composes: quoteDecoration from './QuoteSection.module.css';
  filter: var(--icon-filter-color);
}

.description {
  composes: description from './QuoteSection.module.css';
  font-family: var(--font-roboto);
}

.quoteImage {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

@media (max-width: 768px) {
  .mainImage {
    border-radius: 0.5rem;
    background-image: url('/lp/assets/cz/quotesection/main_mobile_image_cz.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
  }

  .section {
    gap: 12px;
  }

}
