@import url('https://use.typekit.net/aeh0dkr.css');

:root {
  --accent-color: #f1a001;
  --hero-font-color: #fff;
  --quote-section-color: var(--section-background-color);
  --button-large-font-size: 20px;
  --icon-filter-color: brightness(0) saturate(100%) invert(38%) sepia(66%) saturate(1508%) hue-rotate(345deg) brightness(90%) contrast(103%);
  --button-filter-color: brightness(0) saturate(100%) invert(96%) sepia(100%) saturate(0%) hue-rotate(83deg) brightness(104%) contrast(104%);
}

main {
  font-family: var(--font-poppins);
  font-feature-settings: 'ss01' on;
  font-variation-settings: 'opsz' 1;
}

.AdultModal_container__HUE5_ {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 16px;

  background-color: var(--modal-overlay-color);
}

.AdultModal_hidden__Xsi9L {
  display: none;
}

.AdultModal_modal__Jy668 {
  width: 100%;
  max-width: 500px;
  background-color: var(--modal-background-color);
  padding: 36px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: var(--modal-border-radius);
}

.AdultModal_title__z5IpN {
  margin-bottom: 36px;
}

.AdultModal_button___cyFj {
  margin-bottom: 4px;
  justify-content: center;
}

.LocationCollectionSection_wrapper__MPOE0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LocationCollectionSection_title__tR2xD {
}

.LocationCollectionSection_button__h8T_3 {
  background-color: var(--accent-color);
  color: var(--text-accent-color);
  border-radius: 0.75rem;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.09em;
  display: flex;
}

.LocationCollectionSection_left__2NyeJ {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .LocationCollectionSection_wrapper__MPOE0 {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .LocationCollectionSection_left__2NyeJ {
    align-items: flex-start;
    flex-direction: column;
  }
}
.LogosSection_container__o0UxL {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
}

.LogosSection_title__L_JQL {
  text-align: center;
  margin-bottom: 3rem;
}

.LogosSection_logoSectionContainer__4q1ts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  text-align: center;
}

.LogosSection_logos__x_JXr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.LogosSection_logoImage__oT8PX {
  -o-object-fit: contain;
     object-fit: contain;
  width: 88px;
  height: 64px;
}

.LogosSection_biggerLogoImage__vpaQx {
  -o-object-fit: contain;
     object-fit: contain;
  width: 144px;
  height: 88px;
}

.LogosSection_sectionTitle__YvmS_ {
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .LogosSection_logoImage__oT8PX {
    width: 59px;
    height: 43px;
  }
}


.LogosSectionRW_container__1pjKv {
  margin: 7.5rem auto 6.25rem auto;
}

.LogosSectionRW_title__AgWqI {
  font-size: 2.5rem;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
}

.LogosSectionRW_sectionTitle__UeKmF {
  line-height: 1.125rem;
}

.LogosSectionRW_logoSectionContainer__kaFWO {
}

@media (max-width: 600px) {
  .LogosSectionRW_partners__sC6ZF {
    display: none;
  }
  .LogosSectionRW_container__1pjKv {
    margin: 4rem 0 5rem 0;
  }
  .LogosSectionRW_title__AgWqI {
    font-size: 1.75rem;
    line-height: 1.925rem;
    letter-spacing: -0.02em;
    margin-bottom: 2rem;
  }
  .LogosSectionRW_logoSectionContainer__kaFWO {
    gap: 0;
    margin-bottom: 2rem;
  }
}

.CollectionSectionCZ_items__45yRM {
  margin-bottom: 0;
}

.CollectionSectionCZ_items__45yRM a {
  height: -moz-max-content;
  height: max-content;
  display: block;
}

.CollectionSectionCZ_background__VzNLA {
  background-color: #fff;
}

.CollectionSectionCZ_title__KbTyF {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
  text-align: left;
}

@media (max-width: 768px) {
  .CollectionSectionCZ_title__KbTyF {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}

.CollectionSectionPL_section__mtGAp {
}

.CollectionSectionPL_background__zgALD {
  background-color: #fff;
}

.CollectionSectionPL_topBar__la8qZ {
}

@media (max-width: 768px) {
  .CollectionSectionPL_topBar__la8qZ {
    gap: 2rem;
  }
  .CollectionSectionPL_section__mtGAp {
    padding-bottom: 16px;
  }
}

.HeroSection_containerWrapper__FwhtJ {
  display: flex;
  background-color: var(--section-background-color);
}

.HeroSection_container__aGPb9 {
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  display: grid;
  grid-template-areas: 'text' 'slider' 'statistics';
  align-self: center;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 20px;
  gap: 20px;
  padding: 32px 16px;
  grid-template-columns: 1fr;
}

.HeroSection_date__puM1q {
  color: var(--accent-color);
}

.HeroSection_flexboxFirst__9WDOT,
.HeroSection_flexboxSecond__TvEpy {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  flex-basis: auto;
}

.HeroSection_flexboxFirst__9WDOT {
  padding: 64px;
  color: var(--hero-font-color);
  grid-area: text;
  text-align: left;
  box-shadow: none;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  background-image: url('/assets/generic/hero_background.png');
}

.HeroSection_flexboxSecond__TvEpy {
  padding: 36px;
  color: var(--hero-right-color);
  grid-area: slider;
  margin-top: 0;
  background-size: cover;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
  padding: 0;
  max-width: 600px;
  aspect-ratio: 343 / 161;
  background-image: url('/assets/generic/hero_background.png');
}

.HeroSection_pricebox__bZSoG {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 0;
}

.HeroSection_title__KhsSj {
  letter-spacing: -0.03em;
  line-height: 3.25rem;
  font-size: 3rem;
  width: 70vw;
  font-weight: 700;
}

.HeroSection_description__6I3mv {
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  white-space: pre-wrap;
}

.HeroSection_mainPrice__s9d5N {
  font-size: 30px;
  font-weight: 500;
}

.HeroSection_button__vOUxw {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 800;
  line-height: 16.5px;
  letter-spacing: 0.09em;
}

.HeroSection_extra__WlRnR {
  opacity: 0.7;
}

.HeroSection_secondaryText__9hwt4 {
}

.HeroSection_statistics__AVPhZ {
  grid-area: statistics;
}

@media (min-width: 990px) {
  .HeroSection_flexboxSecond__TvEpy {
    justify-self: flex-end;
    width: 350px;
    height: 476px;
  }
}

@media (min-width: 1200px) {
  .HeroSection_flexboxSecond__TvEpy {
    width: 412px;
    height: 560px;
  }
}

@media (min-width: 1440px) {
  .HeroSection_container__aGPb9 {
    width: 1440px;
    grid-template-columns: minmax(450px, 500px) minmax(400px, 450px);
  }
}

@media (max-width: 990px) {
  .HeroSection_container__aGPb9 {
    flex-direction: column;
    gap: 0;
    padding: 16px 16px 32px 16px;
  }

  .HeroSection_flexboxFirst__9WDOT {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 8px;
  }

  .HeroSection_flexboxSecond__TvEpy {
    margin-bottom: 24px;
    width: 100%;
    height: auto;
    aspect-ratio: 343 / 167;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: unset;
  }

  .HeroSection_date__puM1q {
    margin-top: 0;
  }

  .HeroSection_title__KhsSj {
    font-size: 28px;
    line-height: 30.8px;
    width: 50vw;
    max-width: 190px;
  }

  .HeroSection_description__6I3mv {
    font-size: 16px;
    width: 70vw;
    max-width: 450px;
  }

  .HeroSection_title__KhsSj,
  .HeroSection_description__6I3mv {
    margin-bottom: 8px;
    word-break: break-word;
  }

  .HeroSection_pricebox__bZSoG {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 600px) and (max-width: 1340px) {
  .HeroSection_flexboxFirst__9WDOT::after {
    display: none;
  }
}

.heroSectionBW_containerWrapper__H9DRr {
  display: flex;
  background-color: var(--section-background-color);
}

.heroSectionBW_container___ojLt {
}

.heroSectionBW_flexboxFirst__a8RJ7 {
  color: var(--primary-900-color);
}

.heroSectionBW_flexboxFirst__a8RJ7::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/landing-bw/background.svg') no-repeat 0 0;
  pointer-events: none;
}

.heroSectionBW_flexboxSecond__MMYlm {
}

.heroSectionBW_mainPrice__LZll6 {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 700;
}

.heroSectionBW_extra__EqUHZ {
}

.heroSectionBW_mainPrice__LZll6,
.heroSectionBW_extra__EqUHZ {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.heroSectionBW_priceDescription__h6R8k {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.heroSectionBW_date__THu1K {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 3%;
  max-width: 490px;
  margin-top: 16px;
}

.heroSectionBW_title__uxxo1 {
  max-width: 490px;
}

.heroSectionBW_description__DqHwL {
  max-width: 454px;
}

@media (min-width: 990px) {
  .heroSectionBW_container___ojLt {
    padding: 40px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1440px) {
  .heroSectionBW_flexboxFirst__a8RJ7::after {
    display: block;
    aspect-ratio: 679 / 1440;
    height: 679px;
    width: 1440px;
    left: -50px;
    top: -50px;
  }

  .heroSectionBW_date__THu1K {
    margin-bottom: -8px;
  }
}

@media (max-width: 990px) {
  .heroSectionBW_mainPrice__LZll6 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .heroSectionBW_pricebox__A4M0f {
    align-items: flex-start;
    gap: 8px;
  }

  .heroSectionBW_flexboxSecond__MMYlm {
    margin: 32px 0;
  }
}

@media (max-width: 600px) {
  .heroSectionBW_flexboxFirst__a8RJ7::after {
    display: block;
    background: url('/lp/assets/landing-bw/background_mobile.svg') no-repeat 0 0;
    right: -15px;
    top: 25px;
    aspect-ratio: 171 / 167;
    width: 171px;
    height: 167px;
  }

  .heroSectionBW_flexboxSecond__MMYlm {
    margin: 0 0 32px 0;
  }

  .heroSectionBW_description__DqHwL {
    font-size: 15px;
    line-height: 18px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 260px;
  }
}

.HeroSectionCZ_containerWrapper__uUpci {
  display: flex;
  background-color: var(--section-background-color);
}

.HeroSectionCZ_container__I53kb {
}

.HeroSectionCZ_flexboxFirst__gAjvj {
  color: var(--text-body-color);
}

.HeroSectionCZ_flexboxSecond__NQTMO {
}

.HeroSectionCZ_flexboxFirst__gAjvj::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/pl/lips.svg') no-repeat 0 0;
  background-size: contain;
}

.HeroSectionCZ_mainPrice__X2ybT {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 700;
}

.HeroSectionCZ_extra__E1GYq {
}

.HeroSectionCZ_mainPrice__X2ybT,
.HeroSectionCZ_extra__E1GYq {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.HeroSectionCZ_priceDescription__gltYC {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.HeroSectionCZ_date__l3rZl {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 3%;
  max-width: 490px;
  margin-top: 16px;
}

.HeroSectionCZ_title__1__XJ {
  max-width: 490px;
}

.HeroSectionCZ_description__PRvO8 {
  max-width: 490px;
}

.HeroSectionCZ_button___TAbL {
  background-color: #e94d1d;
  margin-bottom: 0;
}

.HeroSectionCZ_button___TAbL:hover {
  background-color: color-mix(in hsl, #e94d1d 90%, white 10%) !important;
}

@media (min-width: 990px) {
  .HeroSectionCZ_container__I53kb {
    padding: 40px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1440px) {
  .HeroSectionCZ_flexboxFirst__gAjvj::after {
    display: block;
    aspect-ratio: 366 / 452;
    height: 452px;
    width: 366px;
    left: 430px;
    top: 75px;
  }

  .HeroSectionCZ_date__l3rZl {
    margin-bottom: -8px;
  }
}

@media (max-width: 990px) {
  .HeroSectionCZ_mainPrice__X2ybT {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .HeroSectionCZ_pricebox__CrFKd {
    align-items: flex-start;
    gap: 8px;
  }

  .HeroSectionCZ_flexboxSecond__NQTMO {
    margin: 32px 0;
  }
}

@media (max-width: 600px) {
  .HeroSectionCZ_flexboxFirst__gAjvj::after {
    display: block;
    transform: scaleX(-1);
    right: -50px;
    top: 100px;
    aspect-ratio: 133 / 164;
    width: 133px;
    height: 164px;
  }
}

.HeroSectionRWPL_container__xxyA3 {
}

.HeroSectionRWPL_flexboxFirst__BS5lt {
}

.HeroSectionRWPL_flexboxSecond__pBV2Z {
}

.HeroSectionRWPL_flexboxFirst__BS5lt::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/pl/hand.svg') no-repeat 0 0;
  background-size: contain;
}

.HeroSectionRWPL_pricebox__Xu1ry {
  max-width: 264px;
}

.HeroSectionRWPL_pricebox__Xu1ry > h2 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.HeroSectionRWPL_mainPrice__5Aah8 {
  font-weight: 800;
  color: var(--accent-color);
}

.HeroSectionRWPL_extra__tg9Qf {
}

h2.HeroSectionRWPL_mainPrice__5Aah8 {
  color: var(--accent-color);
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

.HeroSectionRWPL_mainPrice__5Aah8,
.HeroSectionRWPL_extra__tg9Qf {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.HeroSectionRWPL_date__dkSXf {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 3%;
  margin-top: 32px;
}

.HeroSectionRWPL_title__obdu6 {
  width: 70vw;
  max-width: 548px;
  color: #fff;
}

.HeroSectionRWPL_description__1ZqhI {
  max-width: 459px;
  color: #fff;
  width: 60vw;
}

.HeroSectionRWPL_button__Mja49 {
}

.HeroSectionRWPL_statistics__9ysVa {
}

@media (min-width: 990px) {
  .HeroSectionRWPL_container__xxyA3 {
    padding: 40px 80px 72px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1340px) {
  .HeroSectionRWPL_flexboxFirst__BS5lt::after {
    display: block;
    aspect-ratio: 525 / 643;
    height: 643px;
    width: 525px;
    left: 420px;
    top: 32px;
  }
}

@media (max-width: 990px) {
  h2.HeroSectionRWPL_mainPrice__5Aah8 {
    font-size: 24px;
    line-height: 28px;
  }

  .HeroSectionRWPL_date__dkSXf {
    margin-top: 0;
  }

  .HeroSectionRWPL_title__obdu6 {
    font-size: 28px;
    line-height: 30.8px;
    width: 50vw;
    max-width: 190px;
  }

  .HeroSectionRWPL_description__1ZqhI {
    font-size: 15px;
    line-height: 18px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 245px;
  }

  .HeroSectionRWPL_title__obdu6,
  .HeroSectionRWPL_description__1ZqhI {
    margin-bottom: 16px;
    word-break: break-word;
  }

  .HeroSectionRWPL_pricebox__Xu1ry {
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
  }

  .HeroSectionRWPL_flexboxFirst__BS5lt::after {
    display: block;
    width: 147px;
    height: 181px;
    left: 66%;
    top: -22px;
  }
}

@media (max-width: 768px) {
  .HeroSectionRWPL_button__Mja49 {
    width: 100%;
  }
}

.QuoteSection_section__baabp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 133px;
  background-color: var(--quote-section-color);
  color: var(--text-body-color);
  padding: 0;
}

.QuoteSection_mainImage__Ajn52 {
  width: 530px;
  display: inline-block;
  background-image: url('/lp/assets/generic/hero_background.png');
  background-repeat: no-repeat;
}

.QuoteSection_quoteContainer__NsKLv {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin: 0;
  padding-bottom: 2.5rem;
}

.QuoteSection_quoteDecoration__ox1nv {
  margin-right: 1rem;
}

.QuoteSection_quote__opouj {
  font-size: 2.25rem;
  margin: 4rem 4rem 0 0;
  line-height: 110%;
}

.QuoteSection_quoteFooter__BYMH4 {
  display: flex;
  align-items: center;
  padding: 36px 0;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d9d9d9;
  display: flex;
  flex-direction: row;
  margin-right: 5rem;
  gap: 20px;
}

.QuoteSection_authorNameContainer__Fw19i {
  display: grid;
  grid-row-gap: 4px;
  row-gap: 4px;
  font-weight: 500;
  font-style: normal;
}

.QuoteSection_description__ZRFpy {
  margin-top: auto;
}

@media (max-width: 768px) {
  .QuoteSection_section__baabp {
    flex-direction: column;
    gap: 0;
  }

  .QuoteSection_quoteContainer__NsKLv {
    padding: 1rem 1.5rem 2rem;
  }

  .QuoteSection_quote__opouj {
    font-size: 1.75rem;
    margin: 0;
  }

  .QuoteSection_mainImage__Ajn52 {
    height: 12rem;
  }

  .QuoteSection_quoteFooter__BYMH4 {
    margin-right: 2rem;
  }
}

.QuoteSectionCZ_section__HVccT {
  border-radius: 2rem;
  gap: 92px;
}

.QuoteSectionCZ_mainImage__mK_l4 {
  border-radius: 1.5rem 7.125rem 1.5rem 1.5rem;
  background-image: url('/lp/assets/cz/quotesection/main_image_cz.png');
  margin: 1.5rem;
}

.QuoteSectionCZ_quoteFooter__LZjxF {
  border-style: none;
}

.QuoteSectionCZ_quote__VdFfR {
  font-size: 3rem;

  font-size: 2.25rem;
  font-weight: 700;
  line-height: 3rem;
  text-align: left;
}

.QuoteSectionCZ_quoteDecoration__Q4g6j {
  filter: var(--icon-filter-color);
}

.QuoteSectionCZ_description__XQOeK {
  font-family: var(--font-roboto);
}

.QuoteSectionCZ_quoteImage__0OPOO {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
}

@media (max-width: 768px) {
  .QuoteSectionCZ_mainImage__mK_l4 {
    border-radius: 0.5rem;
    background-image: url('/lp/assets/cz/quotesection/main_mobile_image_cz.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
  }

  .QuoteSectionCZ_section__HVccT {
    gap: 12px;
  }

}

.QuoteSectionRW_section__YoUCG {
  flex-direction: row-reverse;
}

.ReviewsSection_section__z1hw9 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ReviewsSection_title__MQTw9 {
  margin-bottom: 3rem;
}

.ReviewsSection_description__E3q72 {
  text-align: center;
  max-width: 50rem;
}

.ReviewsSection_grid__qe0e8 {
  display: grid;
  grid-template-columns: repeat(3, 412px);
  grid-auto-rows: auto;
  grid-auto-flow: row dense;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: center;
  align-items: start;
}

.ReviewsSection_starRating__RO_dE {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.ReviewsSection_gridItem__b7sA_ {
  background-color: #fef9f4;
  padding: 2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
}

.ReviewsSection_middleColumn__wn8tW {
  transform: translateY(-35px);
  z-index: 1;
}

.ReviewsSection_gridContainer__p_giz {
  display: flex;
  align-items: center;
  height: 40rem;
  margin-top: 6.25rem;
}

.ReviewsSection_text__9q3g4,
.ReviewsSection_author__8I9C_ {
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0.18px;
}

.ReviewsSection_author__8I9C_ {
  font-weight: 700;
}

@media (max-width: 768px) {
  .ReviewsSection_grid__qe0e8 {
    grid-template-columns: 1fr;
  }

  .ReviewsSection_title__MQTw9 {
    margin-bottom: 2rem;
  }

  .ReviewsSection_gridContainer__p_giz {
    height: auto;
    margin-top: 3.5rem;
  }

  .ReviewsSection_middleColumn__wn8tW {
    transform: none;
    display: none; /* show less content on mobile */
  }

  .ReviewsSection_text__9q3g4,
  .ReviewsSection_author__8I9C_ {
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: -0.02em;
  }
}

.ReviewsSectionCZ_title__njWaj {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.02em;
  text-align: left;
  margin-bottom: 48px;
}

.ReviewsSectionCZ_description__sUQrD {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  text-align: center;

  margin-bottom: 96px;
}

.ReviewsSectionCZ_gridContainer__Exk42 {
  @media (min-width: 768px) and (max-width: 1440px) {
    margin-top: 4rem;
  }
}

.ReviewsSectionCZ_grid__AGrkZ {
  @media (min-width: 768px) and (max-width: 1440px) {
    grid-template-columns: repeat(2, 375px);
  }
}

.ReviewsSectionCZ_gridItem__sZ4DA {
  background-color: var(--section-background-color);
}

.ReviewsSectionCZ_starRating__FVEUm {
  filter: var(--icon-filter-color);
}

.ReviewsSectionCZ_starRating__FVEUm img {
  width: 20px;
  height: 20px;
}

.ReviewsSectionCZ_text__zt4qr {
}

.ReviewsSectionCZ_author__wBekk {
}

.ReviewsSectionCZ_avatars__lfF0X {
  margin-bottom: 24px;
}

@media (max-width: 600px) {
  .ReviewsSectionCZ_title__njWaj {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: center;
    margin-bottom: 32px;
  }

  .ReviewsSectionCZ_description__sUQrD {
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 50px;
  }

  .ReviewsSectionCZ_text__zt4qr {
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .ReviewsSectionCZ_author__wBekk {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.alice-carousel .animated {
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__stage-item * {
  line-height: initial;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__next-btn {
  text-align: left;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred;
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}
.alice-carousel__play-btn:hover {
  cursor: pointer;
}
.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}
.alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: "";
  transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #465798;
}
.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}
.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}
.alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}
.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center;
}
.alice-carousel__dots > li {
  display: inline-block;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}
.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/lp/_next/static/media/f10b8e9d91f3edcb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/lp/_next/static/media/8888a3826f4a3af4-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/a1386beebedccca4-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_14c039';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/b957ea75a84b6ea7-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_14c039';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_14c039 {font-family: '__Poppins_14c039', '__Poppins_Fallback_14c039';font-style: normal
}.__variable_14c039 {--font-poppins: '__Poppins_14c039', '__Poppins_Fallback_14c039'
}

